<template>
  <div>
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <button @click="search" class="btn btn-outline-secondary" type="button">Search</button>
      </div>
      <input type="text" class="form-control" placeholder="Input the survey id here" aria-label="surveyid"
        aria-describedby="basic-addon1" v-model="surveyId">
      <input type="text" class="form-control" placeholder="Input the token here" aria-label="surveyid"
        aria-describedby="basic-addon1" v-model="token">
    </div>

    <div>
      Select language for the spell check:
      <label>
        <input type="radio" v-model="selectedOption" value="nl" />
        Dutch
      </label>
      <label>
        <input type="radio" v-model="selectedOption" value="en" />
        English
      </label>
    </div>

    <div class="center-container">
      <p style="font-family: Arial, sans-serif; font-size: 24px; color: #070707;">Stimulus: {{ currentStimulus }}</p>
      <p>({{ currentIndex }}/{{ totalCount }})</p>
    </div>
    <div class="button-container">
      <button @click="nextStimulus" type="button" class="btn btn-primary">Next</button>
      <button @click="previousStimulus" type="button" class="btn btn-secondary">Previous</button>
      <!-- submit the current survey -->
      <button @click="openPopupSubmit()" type="button" class="btn btn-outline-dark">Submit</button>
      <div v-if="showSubmitPopup" class="popup">
        <button @click="submitSurvey">Submit</button>
        <button @click="closePopupSubmit">Cancel</button>
      </div>
    </div>
    <br>
    <div class="center-container">
      <div class="histogram">
        <div v-for="word in sortedWords" :key="word" class="bar">
          <div class="button-container">
            <div class="label">{{ word }}</div>
            <div class="count-bar" :style="{ width: getCountPercentage(word) }"></div>
            <div class="count">{{ wordFrequencies[word] }}</div>

            <!-- to remove the association -->
            <button @click="openPopupDelete(word)" type="button" class="btn btn-outline-danger">Remove</button>
            <div v-if="showDeletePopup" class="popup">
              <button @click="removeAssociations">Delete</button>
              <button @click="closePopupDelete">Cancel</button>
            </div>

            <!-- to modify the association -->
            <button @click="openPopupModify(word)" type="button" class="btn btn-outline-secondary">Modify</button>
            <div v-if="showModifyPopup" class="popup">
              <input type="text" v-model="popupInput">
              <button @click="confirmPopupModify">Modify</button>
              <button @click="closePopupModify">Cancel</button>
            </div>

            <!-- add no_idea to the data -->
            <button @click="AddNoIdeaMark(word)" type="button" class="btn btn-outline-warning">No_idea</button>

            <!-- add suggestion list -->
            <div v-for="s in getSuggestions(word)" :key="s" :word="s">
              <button type="button" @click="click2modify(s, word)" class="btn btn-primary" style="width: auto">{{ s
              }}</button>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
  
<script>
// import Suggestion from './Suggestion.vue';
export default {
  // components: { Suggestion },
  data() {
    return {
      isLoading: false,
      selectedOption: 'en', //set initial value = english
      suggestion: [],
      token: '',
      envVars: {},
      resultList: [],
      removeList: [],
      adaptList: [],
      noIdeaList: [],
      wordFrequencies: {},
      matchedAssociations: {}, //this will show all the associations matched with the current stimuli
      showModifyPopup: false,
      showDeletePopup: false,
      showSubmitPopup: false,
      popupInput: '',
      currentWord: '', //this keeps track of the current word when u click the button next to it
      currentStimulus: '',
      currentIndex: null,
      totalCount: null,
      stimulusData: [],
      error: null,
      surveyId: null,
    };
  },
  // mounted() {
  //   this.fetchData();
  // },

  computed: {
    sortedWords() {
      return Object.keys(this.wordFrequencies).sort((a, b) => {
        return this.wordFrequencies[b] - this.wordFrequencies[a];
      });
    },
  },
  created: function () {
    this.loadEnvVar('VUE_APP_API');
  },

  methods: {
    search() {
      //select the survey based on the id
      this.fetchData(this.surveyId, this.token, this.selectedOption);
    },

    loadEnvVar: function (varName) {
      let varValue = '';
      if (typeof (process.env[varName]) !== 'undefined') {
        varValue = process.env[varName];
      }
      else if (document.querySelector(`meta[name="${varName}"]`) !== null) {
        varValue = document.querySelector(`meta[name="${varName}"]`).content;
      }
      this.envVars[varName] = varValue;
    },

    getSuggestions(word) {
      const result = this.resultList.find(item => {
        return item.Stimulus === this.currentStimulus && item.Association === word;
      });
      if (result) {
        return result.Suggestions;
      }
    },

    async fetchData(id, token, selectedOption) {
      this.isLoading = true;
      // await fetch(`http://127.0.0.1:5000/surveys/allData?id=${id}&token=${token}&lan=${selectedOption}`, {
        await fetch(`${this.envVars.VUE_APP_API}/surveys/allData?id=${id}&token=${token}&lan=${selectedOption}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(response => response.json())
        .then(data => {
          this.resultList = data//objrct array
          this.stimulusData = this.resultList.reduce((acc, item) => {
            if (!acc.includes(item.Stimulus)) {
              acc.push(item.Stimulus);
            }
            return acc;
          }, []);

          // set the first stimuli in the first page
          this.currentStimulus = this.stimulusData[0];
          this.currentIndex = 1;
          // set the total amount of data
          this.totalCount = this.stimulusData.length;

          // procress data for the histogram
          this.matchedAssociations = this.resultList
            .filter(item => item.Stimulus === this.currentStimulus)
            .map(item => item.Association);
          // update the wordFrequencies
          this.wordFrequencies = this.matchedAssociations.reduce((freq, token) => {
            freq[token] = (freq[token] || 0) + 1;
            return freq;
          }, {});
          this.isLoading = false;
        })
        .catch(error => {
          this.error = error.message;
          console.log(this.error);
          this.isLoading = false;
        });
    },

    countWordFrequencies() {
      const tokens = [];
      // Count word frequencies
      this.wordFrequencies = tokens.reduce((freq, token) => {
        freq[token] = (freq[token] || 0) + 1;
        return freq;
      }, {});
    },

    getCountPercentage(word) {
      // Calculate the percentage width of the count bar
      const totalCount = Object.values(this.wordFrequencies).reduce(
        (total, count) => total + count,
        0
      );
      const count = this.wordFrequencies[word];
      const percentage = (count / totalCount) * 100;
      return `${percentage}%`;
    },

    //put the words in the remove list
    removeAssociations() {
      this.resultList = this.resultList.filter(item => {
        if ((item.Association !== this.currentWord && item.Stimulus === this.currentStimulus) || (item.Stimulus !== this.currentStimulus)) {
          return item;
        } else {
          let association_id = item.Association_id;
          const isDuplicate = this.removeList.find(item => item.Association_id === association_id);
          if (!isDuplicate) {
            this.removeList.push({ Association_id: item.Association_id, Association: item.Association });
          }
        }
      });
      this.matchedAssociations = this.resultList
        .filter(item => item.Stimulus === this.currentStimulus)
        .map(item => item.Association);
      // update the wordFrequencies
      this.wordFrequencies = this.matchedAssociations.reduce((freq, token) => {
        freq[token] = (freq[token] || 0) + 1;
        return freq;
      }, {});
      this.closePopupDelete();
    },

    // click to modify the word by the suggestions
    click2modify(modifiedWord, word) {
      this.currentWord = word;
      this.resultList = this.resultList.map(item => {
        if (item.Association === this.currentWord && item.Stimulus === this.currentStimulus) {
          let association_id = item.Association_id;
          const isDuplicate = this.adaptList.find(item => item.Association_id === association_id);
          if (!isDuplicate) {
            this.adaptList.push({ Adapt: modifiedWord, Association_id: item.Association_id })
          }
          return { Association: modifiedWord, Association_id: item.Association_id, Stimulus: item.Stimulus };
        }
        return item;
      });
      this.matchedAssociations = this.resultList
        .filter(item => item.Stimulus === this.currentStimulus)
        .map(item => item.Association);
      // update the wordFrequencies
      this.wordFrequencies = this.matchedAssociations.reduce((freq, token) => {
        freq[token] = (freq[token] || 0) + 1;
        return freq;
      }, {});
    },

    // put the word in the adapt list
    confirmPopupModify() {
      this.resultList = this.resultList.map(item => {
        if (item.Association === this.currentWord && item.Stimulus === this.currentStimulus) {
          let association_id = item.Association_id;
          const index = this.adaptList.findIndex(item => item.Association_id === association_id);
          if (index !== -1) {
            // Update the element at the found index
            this.adaptList[index] = { Adapt: this.popupInput, Association_id: item.Association_id };
          } else {
            // Element not found, add a new element to the list
            this.adaptList.push({ Adapt: this.popupInput, Association_id: item.Association_id });
          }

          return { Association: this.popupInput, Association_id: item.Association_id, Stimulus: item.Stimulus };
        }
        return item;
      });
      this.matchedAssociations = this.resultList
        .filter(item => item.Stimulus === this.currentStimulus)
        .map(item => item.Association);
      // update the wordFrequencies
      this.wordFrequencies = this.matchedAssociations.reduce((freq, token) => {
        freq[token] = (freq[token] || 0) + 1;
        return freq;
      }, {});
      this.closePopupModify();
    },

    // add the no idea mark on the list
    AddNoIdeaMark(word) {
      this.currentWord = word;

      this.resultList = this.resultList.map(item => {
        // if already added no idea mark => remove the mark
        if (this.currentWord.endsWith('?????') && item.Association === this.currentWord && item.Stimulus === this.currentStimulus) {
          let association_id = item.Association_id;
          this.noIdeaList = this.noIdeaList.filter(item => item.Association_id !== association_id);
          return { Association: this.currentWord.replace(/\?{5}$/, ""), Association_id: item.Association_id, Stimulus: item.Stimulus };
        }
        // if not added no idea mark => add the mark
        if (!(this.currentWord.endsWith('?????')) && item.Association === this.currentWord && item.Stimulus === this.currentStimulus) {
          let association_id = item.Association_id;
          const isDuplicate = this.noIdeaList.find(item => item.Association_id === association_id);
          if (!isDuplicate) {
            this.noIdeaList.push({ Association: item.Association, Association_id: item.Association_id });
          }
          return { Association: this.currentWord + '?????', Association_id: item.Association_id, Stimulus: item.Stimulus };
        }
        return item;
      });
      this.matchedAssociations = this.resultList
        .filter(item => item.Stimulus === this.currentStimulus)
        .map(item => item.Association);

      // update the wordFrequencies
      this.wordFrequencies = this.matchedAssociations.reduce((freq, token) => {
        freq[token] = (freq[token] || 0) + 1;
        return freq;
      }, {});
    },

    submitSurvey() {
      // process the data to be sent to the api

      // deletes list
      const deletes = this.removeList.map(item => item.Association_id);

      // no_idea list
      const no_ideas = this.noIdeaList.map(item => {
        if (!deletes.includes(item.Association_id)) {
          deletes.push(item.Association_id);
        }
        return { 'association_id': item.Association_id, "stimulus_id": null }
      });

      // adapt list
      const temp = this.adaptList.map(item => {
        return {
          'response_row_id': item.Association_id,
          'adapt_to': item.Adapt
        };
      });
      const adapts = temp.filter((element) => !deletes.includes(element.response_row_id));

      // process data in json format
      const sendData = { 'survey_id': this.surveyId, 'deletes': deletes, 'adapts': adapts, 'no_ideas': no_ideas };
      const jsonData = JSON.stringify(sendData);

      // pass data to the back-end
      if (this.surveyId) {
        // fetch(`http://127.0.0.1:5000/restful/surveys/${this.surveyId}/response_review_histogram`, {
          fetch(`${this.envVars.VUE_APP_API}/restful/surveys/${this.surveyId}/response_review_histogram`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(jsonData)
        })
          .then(response => response.json())
          .then(data => {
            // Process the data returned from the API
            if (data['status']) {
              window.location.reload();
            }
          })
          .catch(error => {
            // Handle any errors that occur during the request
            console.error('Error:', error);
          });
      }

      this.closePopupSubmit();
    },

    openPopupDelete(word) {
      this.currentWord = word;
      this.showDeletePopup = true;
    },

    openPopupModify(word) {
      this.showModifyPopup = true;
      this.currentWord = word;
    },

    openPopupSubmit() {
      this.showSubmitPopup = true;
    },

    closePopupDelete() {
      this.showDeletePopup = false;
    },

    closePopupModify() {
      this.showModifyPopup = false;
      this.popupInput = '';
    },

    closePopupSubmit() {
      this.showSubmitPopup = false;
    },


    nextStimulus() {
      if (this.currentIndex < this.totalCount) {
        this.currentIndex++;
      }
      const currentIndex = this.stimulusData.indexOf(this.currentStimulus);
      if (currentIndex >= 0 && currentIndex < this.stimulusData.length - 1) {
        const nextElement = this.stimulusData[currentIndex + 1];
        this.currentStimulus = nextElement;
        // procress data for the histogram
        this.matchedAssociations = this.resultList
          .filter(item => item.Stimulus === this.currentStimulus)
          .map(item => item.Association);
        // update the wordFrequencies
        this.wordFrequencies = this.matchedAssociations.reduce((freq, token) => {
          freq[token] = (freq[token] || 0) + 1;
          return freq;
        }, {});
      }
    },

    previousStimulus() {
      if (this.currentIndex > 1) {
        this.currentIndex--;
      }
      const currentIndex = this.stimulusData.indexOf(this.currentStimulus);
      if (currentIndex > 0 && currentIndex <= this.stimulusData.length - 1) {
        const lastElement = this.stimulusData[currentIndex - 1];
        this.currentStimulus = lastElement;
        // procress data for the histogram
        this.matchedAssociations = this.resultList
          .filter(item => item.Stimulus === this.currentStimulus)
          .map(item => item.Association);
        // update the wordFrequencies
        this.wordFrequencies = this.matchedAssociations.reduce((freq, token) => {
          freq[token] = (freq[token] || 0) + 1;
          return freq;
        }, {});
      }
    }


  },
};
</script>
  
<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";

.histogram {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bar {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.label {
  width: 200px;
}

.count-bar {
  height: 20px;
  background-color: rgb(65, 65, 247);
  margin-left: 30px;
  /* z-index: 999; */
}

.count {
  font-weight: bold;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  /* Set the desired space between buttons */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-top-color: #000;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>