<template>
  <div class='response_row' style='display: flex; justify-content: left;'>
    <div style='width: 4%;'>
      <span
        class="badge bg-secondary"
        title="Number of repetitions in this survey"
        >{{ info.counter }}
      </span>
    </div>
  
    <div style="width: 35%;" :style="{backgroundColor : color}">
      <span>{{ this.info.response }}</span>
      <button
        class='btn-sm btn-danger'
        v-if="state === 'possible_delete'"
        @click="ignore"
        >IGNORE
      </button>
    </div>
    
    <input type='checkbox' class='delete-cb' style='width: 6%;' v-model="is_deleted">
  
    <input type='checkbox' class='comment-cb' style='width: 6%;' v-model="is_comment">
  
    <input type='text' class='adapt-input'  v-model="adapt_to">
  
    <button class='btn btn-outline-primary clear-adapt-btn' @click="clear">
      🧹
    </button>
  
    <div style='display:inline;'>
      <suggestion
        v-for="s in info.suggestions"
        :key="s"
        :word="s"
        @suggestion_selected="on_suggestion_selected">
      </suggestion>
    </div>
    </div>
</template>

<script>
import Suggestion from './Suggestion.vue';

export default {
  components: { Suggestion },
  emits: ['ignore_word'],
  props: {
    info: { required: true, type: Object },
    ignored_words: { required: true, type: Array },
  },
  computed: {
    state: function() {
      let result = 'no_action_needed';
      // response should be marked as possible comment if the API reports it
      // as a comment
      if (this.info.is_comment) {
        result = 'possible_comment';
      }
      // response should be marked as possible delete if it's not in
      // spellcheck nor our DB, and it is not one of the ignored words
      else if (!this.info.is_in_db &&
               !this.info.is_in_spellcheck &&
               !this.ignored_words.includes(this.info.response)) {
        result = 'possible_delete';
      }
      return result;
    },
    color: function() {
      let result = 'transparent';
      switch (this.state) {
        case 'possible_comment': result = '#cce6ff'; break;
        case 'possible_delete': result ='#ffccb3'; break;
      }
      return result;
    },
  },
  data: function() {
    return {
      is_deleted: false,
      is_comment: false,
      adapt_to: '',
    }
  },
  methods: {
    on_suggestion_selected: function(suggestion) {
      this.adapt_to = suggestion;
    },
    clear: function() {
      this.adapt_to = '';
    },
    ignore: function() {
      this.$emit('ignore_word', this.info.response);
      this.is_deleted = false;
    },
    get_payload: function() {
      return {
        response: this.info.response,
        response_row_id: this.info.response_row_id,
        is_deleted: this.is_deleted,
        is_comment: this.is_comment,
        adapt_to: this.adapt_to,
      };
    },
  },
  mounted: function() {
    this.is_deleted = this.state === 'possible_delete';
    this.is_comment = this.state === 'possible_comment';
  },
  watch: {
    adapt_to: function(new_val) {
      if (new_val !== '') {
        this.is_deleted = false;
        this.is_comment = false;
      }
    },
    is_deleted: function(new_val) {
      if (new_val) {
        this.adapt_to = '';
        this.is_comment = false;
      }
    },
    is_comment: function(new_val) {
      if (new_val) {
        this.adapt_to = '';
        this.is_deleted = false;
      }
    },
  },
}
</script>
