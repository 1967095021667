<template>
  <div>
    <div class="text-center">
      <h3>Survey {{ survey_id }} - Completed {{ completed_count }} of {{ respondent_count }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    survey_id: { required: true, type: Number },
    completed_count: { required: true, type: Number },
    respondent_count: { required: true, type: Number },
  },
}
</script>
