<template>
  <loading-screen v-if="is_loading" />
  <div class="container" v-if="!is_loading">
    <app-header
      :survey_id="survey_id"
      :completed_count="completed_count"
      :respondent_count="respondent_count">
    </app-header>
    <stimulus
      v-for="s in stimuli"
      :key="`${current_respondent_id}-${s.stimulus_id}`"
      :ref="refFn"
      :info="s"
      :ignored_words="ignored_words"
      @ignore_word="on_ignore_word">
    </stimulus>
    <div v-show="!next_respondent_loading">
      <button class="next_btn" @click="next">Next</button>
      <button class="delete_all_btn" @click="delete_all">Delete all</button>
    </div>
    <div v-show="next_respondent_loading">
      Loading the next respondent. The "Next" button will show up once it is
      loaded.
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useRoute } from 'vue-router';
import AppHeader from '@/components/AppHeader.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';
import Stimulus from '@/components/Stimulus.vue';

export default {
  components: { AppHeader, Stimulus, LoadingScreen },
  data: function() {
    return {
      survey_id: 0,
      is_loading: true,
      next_respondent_loading: true,
      completed_count: 0,
      respondent_count: 0,
      current_respondent_id: 0,
      ignored_words: [],
      respondent_queue: [],
      stimuli:[],
      stimuli_components: [],
    }
  },
  methods: {
    refFn: function(stimulus_component) {
      if (stimulus_component) {
        this.stimuli_components.push(stimulus_component);
      }
    },
    /* Load the next respondent from the respondent_queue */
    next_respondent: function() {
      // if the queue isn't populated, ask the user to reload
      if (this.respondent_queue.length === 0) {
        alert(`The server is not responding. Please refresh the page. Your data has been saved.`);
      }
      // get the next respondent from the queue
      const data = this.respondent_queue.shift();
      // empty the stimulus list
      this.stimuli.splice(0, this.stimuli.length);
      if (data.finished) {
        this.$router.push({ name: 'Finished' });
      } else {
        // if we're not finished yet get the respondent that comes after the
        // one we just loaded in the background (ie async)
        this.next_respondent_loading = true;
        this.fetch_respondent(data.respondent_id);
        this.current_respondent_id = data.respondent_id;
        this.completed_count += 1;
        this.stimuli = data.stimuli;
        this.is_loading = false;
      }
      // scroll to the top after the page has been updated
      setTimeout(() => { window.scrollTo(0,0) }, 100);
    },
    on_ignore_word: function(word) {
      this.ignored_words.push(word);
    },
    fetch_respondent: function(resp_id) {
      return axios.get(`/${resp_id}`).then(response => {
        this.respondent_queue.push(response.data);
        this.next_respondent_loading = false;
      });
    },
    get_total_respondent_count: function() {
      return axios.get('/respondent_count').then(response => {
        this.respondent_count = response.data.respondent_count;
      });
    },
    get_processed_respondent_count: function() {
      return axios.get('/respondents_processed').then(response => {
        this.completed_count = response.data.completed_respondents;
      })
    },
    delete_all: function() {
      if (confirm('Delete all responses for this respondent?')) {
        const payload = this.get_payload();
        payload.delete_all = true;
        this.save_and_next(payload);
      }
    },
    next: function() {
      const payload = this.get_payload();
      payload.delete_all = false;
      this.save_and_next(payload);
    },
    save_and_next: function(payload) {
      this.stimuli_components.splice(0, this.stimuli_components.length);
      axios.post('/', payload).then(() => { this.next_respondent(); });
    },
    get_payload: function() {
      const payload = {
        survey_id: this.survey_id,
        respondent_id: this.current_respondent_id,
        stimuli: [],
      };
      for (const s of this.stimuli_components) {
        payload.stimuli.push(s.get_payload());
      }
      return payload;
    },
  },
  created: async function() {
    const route = useRoute();
    const survey_id = parseInt(route.query.survey_id);
    this.survey_id = survey_id;
    
    // we perform a single HTTP request - this will tell us whether the token is
    // correct; if it is not we abort by redirecting to the 'wrong token' page
    try {
      await this.get_total_respondent_count();
      // if this request succeeds we can proceed with the remaining requests
      axios.all([
        this.fetch_respondent(0), // first unprocessed respondent
        this.get_processed_respondent_count()
      ]).then(() => { this.next_respondent(); });
    } catch(error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'Wrong Token' });
      } else {
        alert('There was an error. Please try later.');
      }
    }
  },
  beforeUpdate() {
    // we need to do this in order for the refFn to work correctly
    this.stimuli_components = [];
  },
}
</script>

<style scoped>
.next_btn {
    display: inline-block;
    width:85%;
    height:4em;
    color:white;
    background-color:#00c851;
}
.delete_all_btn {
    display: inline-block;
    width:15%;
    float: right;
    height:4em;
    color:white;
    background-color:#dc3545;
}
</style>
