<template>
  <div class="card mb-2">
    <div class="card-header">
      <span>{{ info.stimulus }}</span>
      <button
        class='btn btn-outline-danger no-idea-btn'
        style='float: right;'
        @click="no_idea"
        >{{ no_idea_string }}
      </button>
    </div>
    <div class="card-body" v-show="!is_no_idea">
      <div style='display: flex; justify-content: left;'>
        <div style='width: 3%;'></div>
        <div style='width: 4%;'></div>
        <div style='width: 35%;'>RESPONSE</div>
        <div style='width: 6%;' title='Should be deleted'>DEL</div>
        <div style='width: 6%;' title='Is a comment'>COM</div>
      </div>
      <response
        v-for="r in info.responses"
        :key="r.response_row_id"
        :ref="refFn"
        :info="r"
        :ignored_words="ignored_words"
        @ignore_word="on_ignore_word">
      </response>
    </div>
  </div>
</template>

<script>
import Response from './Response.vue';

export default {
  components: { Response },
  emits: ['ignore_word'],
  inject: ['envVars'],
  props: {
    info: { required: true, type: Object },
    ignored_words: { required: true, type: Array },
  },
  data: function() {
    return {
      is_no_idea: false,
      response_components: [],
      no_idea_string: this.envVars.VUE_APP_NO_IDEA_STRING,
    }
  },
  methods: {
    refFn: function(response_component) {
      if (response_component) {
        this.response_components.push(response_component);
      }
    },
    no_idea: function() {
      this.is_no_idea = !this.is_no_idea;
    },
    on_ignore_word: function(word) {
      this.$emit('ignore_word', word);
    },
    get_payload: function() {
      const payload = {
        stimulus_id: this.info.stimulus_id,
        stimulus: this.info.stimulus,
        is_no_idea: this.is_no_idea,
        responses: [],
      };
      for (const r of this.response_components) {
        payload.responses.push(r.get_payload());
      }
      return payload;
    },
  },
  beforeUpdate() {
    this.response_components = [];
  },
}
</script>
