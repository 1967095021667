import { createRouter, createWebHashHistory } from 'vue-router';
import Review from "@/components/Review.vue";
import WrongToken from "@/components/WrongToken.vue";
import FinishedScreen from "@/components/FinishedScreen.vue";
import Histogram from "@/components/Histogram.vue";

const routes = [
  {
    path: '/review',
    name: 'Review',
    component: Review,
  },
  {
    path: '/wrong_token',
    name: 'Wrong Token',
    component: WrongToken,
  },
  {
    path: '/finished',
    name: 'Finished',
    component: FinishedScreen,
  },
  {
    path: '/histogram',
    name: 'Histogram',
    component: Histogram,
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router;
