<template>
  <button class='btn btn-outline-primary' @click="suggestion_click">
    {{ word }}
  </button>
</template>

<script>
export default {
  emits: ['suggestion_selected'],
  props: {
    word: { required: true, type: String },
  },
  methods: {
    suggestion_click: function() {
      this.$emit('suggestion_selected', this.word);
    },
  },
}
</script>
