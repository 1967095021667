<template>
  <router-view />
</template>

<script>
import axios from "axios";

export default {
  data: function() {
    return {
      envVars: {},
    }
  },
  methods: {
    loadAllEnvVars: function() {
      const allEnvVars = ['VUE_APP_API', 'VUE_APP_NO_IDEA_STRING'];
      for (const envVar of allEnvVars) {
        this.loadEnvVar(envVar);
      }
    },
    loadEnvVar: function(varName) {
      let varValue = '';
      // first, try to load the variable from the standard vue-cli process.env
      if (typeof(process.env[varName]) !== 'undefined') {
        varValue = process.env[varName];
      }
      // if the env var is not specified in process.env we try to get it from
      // <meta> in index.html (if it's not specified there either it will be
      // set to empty string)
      else if (document.querySelector(`meta[name="${varName}"]`) !== null) {
        varValue = document.querySelector(`meta[name="${varName}"]`).content;
      }
      this.envVars[varName] = varValue;
    },
  },
  created: function() {
    this.loadAllEnvVars();
    // we get the survey id and the token from the URL params
    const route = new URL(window.location.href.replace(/#\//g, ""));
    const survey_id = parseInt(route.searchParams.get('survey_id'));
    const token = route.searchParams.get('token');
    
    // we set the base URL in axios to include the survey id; we also include
    // the token as a header (X-Token) in each axios request
    axios.defaults.baseURL = `${this.envVars.VUE_APP_API}/restful/surveys/${survey_id}/response_review`;
    axios.defaults.headers.common['X-Token'] = token;
  },
  provide: function() {
    return {
      envVars: this.envVars,
    }
  },
}
</script>
